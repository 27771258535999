import React from "react";
// libs
import Image from "next/image";
import { DateTime } from "luxon";
// hooks
import { useAppSelector } from "src/hooks";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
// const
import { NEXT_WEBINAR_BLOCK } from "src/constants/dynamic";
// type
import { IDynamicNextWebinar } from "src/types/dynamic/clinics/nextWebinar";
// theme
import {
  Wrapper,
  Content,
  TitleWrapper,
  Card,
  TextContent,
  Metadata,
  Webinar,
  Point,
  Date,
  Text,
  Bottom,
  Label,
  WebinarImage,
  DoodleIcon,
} from "./FeaturedPost.styles";
import { useRouter } from "next/router";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
import { colors } from "src/theme";
import { LargeTitle } from "src/theme/typography";

const FeaturedPost = () => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicNextWebinar>(NEXT_WEBINAR_BLOCK));
  const { pathname } = useRouter();

  const dateToShow = DateTime.fromString(data?.date || "", "yyyy-LL-dd").toFormat("dd LLLL yyyy");

  return (
    <Wrapper>
      <Content>
        <TitleWrapper>
          <LargeTitle textColor={colors.white}>Join our next webinar</LargeTitle>
        </TitleWrapper>
        <Card>
          <WebinarImage>
            <Image
              objectFit="cover"
              width={412}
              height={344}
              src={data?.image?.url || "/"}
              loader={({ src }) => src}
            />
          </WebinarImage>
          <TextContent>
            <Metadata>
              <Webinar>Webinar</Webinar>
              <Point>&middot;</Point>
              <Date>{dateToShow}</Date>
            </Metadata>
            <LargeTitle as="h3">{data?.title}</LargeTitle>
            <Text>{data?.text}</Text>
            <Bottom>
              {data?.links.map((link) => (
                <Label
                  key={link.id}
                  href={link.url}
                  target={link.new_tab ? "_blank" : "_self"}
                  type={link.type}
                  onClick={() =>
                    amplitudeEvent(formatEventName(pathname, "featured_blog", link.title))
                  }
                >
                  {link.title}
                </Label>
              ))}
            </Bottom>
          </TextContent>
          <DoodleIcon>
            <Image width={151} height={88} src="/doodle/doodle-double-line.svg" />
          </DoodleIcon>
        </Card>
      </Content>
    </Wrapper>
  );
};

export default FeaturedPost;
