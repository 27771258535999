import styled, { css } from "styled-components";
import device from "src/constants/media";
import { typography, colors } from "src/theme";

export const Wrapper = styled.div``;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 96px 0;
  gap: 24px;
  margin-inline: auto;
  margin-top: 80px;

  @media ${device.laptop} {
    padding: 0 86px 196px 86px;
    gap: 48px;
    margin-top: 160px;
  }
  @media ${device.laptopS} {
    max-width: 1096px;
    padding: 0 0 196px 0;
  }
`;
export const TitleWrapper = styled.div`
  padding: 0 24px 0 24px;
  @media ${device.tablet} {
    padding-left: 72px;
  }
  @media ${device.laptop} {
    padding: 0;
  }
`;
export const Card = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
  background: ${colors.white};
  box-shadow: 0px 8px 64px -32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media ${device.tablet} {
    padding: 48px 72px;
  }
  @media ${device.laptop} {
    flex-direction: row;
    padding: 56px;
    justify-content: space-between;
  }
  @media ${device.laptopS} {
    padding: 80px 112px;
  }
`;
export const DoodleIcon = styled.div`
  position: absolute;
  bottom: -60px;
  right: -75px;
`;
export const WebinarImage = styled.div`
  border-radius: 16px;
  overflow: hidden;
  > div {
    display: block !important;
  }
`;
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    width: 412px;
    padding-left: 48px;
  }
`;
export const Metadata = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 0;
  @media ${device.tablet} {
    margin: 0 auto;
  }
  @media ${device.laptop} {
    margin: 0;
  }
`;
export const Webinar = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.greyDark};
`;
export const Point = styled.p`
  padding: 0 12px;
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.grey};
`;
export const Date = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.grey};
`;

const truncateText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const LargeTitle = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: ${colors.greyDark};
  ${truncateText}
`;
export const Text = styled.p`
  padding: 12px 0 0;
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.grey};
  ${truncateText}
`;
export const Bottom = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  height: 52px;
`;
export const Label = styled.a<{ type: string }>`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ type }) => (type === "primary" ? colors.main : colors.grey)};

  :not(:first-child) {
    padding-left: 16px;
  }
`;
